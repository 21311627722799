import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/pteen/_src/a4i/website/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Why do I not see my chart data?`}</h1>
    <p>{`Analytics for Intercom uses the Intercom API to get products. The API naturally has limitations and doesn't always provide historical data.`}</p>
    <p>{`We only receive the current values for some data from Intercom's API. We thus need time to accumulate data over time to make some charts charts valuable. Until then you may see partial data or this.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/652493d826a4489b1266ca2b00f83477/302da/data.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.16216216216216%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAACE4AAAhOAFFljFgAAABGElEQVQoz6WSfW+CMBDG+f7fbfGPxWTGZAJTIDPL6JsCpfesV21lqFs2jzwpKXe/eyMjogLeDoeD6/seXddhHEcQPzQXgm5YuPU+LvNnI4TA4mlBq5cVls9LKKmAu3G3ElGCZuSo5mpyVVAuCxSqDHqVmyB/75WjlA3W7xIfxxZiEBD9RW3f0uCGAE3A2jS0MxUqUwcx9E1vsdU7lLpEpffY7BU+jxJqkJAzWWdDD5lzrj53Q/jVTkP0RUxb/faegHSyH6neNwXGGniRfqHXwDhUDuItW2thjAnO98Tf+a/gmFhpAvpMpLVOED4ZyvAoDpgmnIKuKvQOFIP+YvM5MrCKI7qM8v/GwGaa6VHLfJvludzByz6o4QuBlK5u5cf1vAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "'Gathering Data'",
            "title": "'Gathering Data'",
            "src": "/static/652493d826a4489b1266ca2b00f83477/46d55/data.png",
            "srcSet": ["/static/652493d826a4489b1266ca2b00f83477/8b5e5/data.png 259w", "/static/652493d826a4489b1266ca2b00f83477/2f227/data.png 518w", "/static/652493d826a4489b1266ca2b00f83477/46d55/data.png 1035w", "/static/652493d826a4489b1266ca2b00f83477/302da/data.png 1207w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      